import React from 'react'
import { PageWrapper } from '../components/pageComponents'
import styled from 'styled-components'
import { medias } from '../utils/mediaQueries'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  size: 1em;

  @media ${medias.phoneMax} {
    width: 90%;
  }
`

const page404 = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <h1>Página não encontrada :(</h1>
        <p>Parece que a página para a qual você navegou não existe.</p>
        <p>
          Clique <Link to="/">aqui</Link> para retornar para a página inicial.
        </p>
      </Wrapper>
    </PageWrapper>
  )
}

export default page404
